import React, { useState } from 'react';
import axios from 'axios';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import Table from './Table/Table';
import FilterActionBar from './FilterActionBar/FilterActionBar';

const columnMap = {
  storeName: 'Store',
  timeWantedChanged: '',
  oloRef: 'Order ID/Invoice #',
  timeWanted: 'Time Wanted',
  timePlaced: 'Time Placed',
  name: 'Booking Client Name',
  emailAddress: 'Booking Client Email',
  phoneNumber: 'Phone',
  paymentType: 'Payment',
  total: 'Total',
  tax: 'Tax',
  tip: 'Tip',
  deliveryAddress: 'Delivery Address',
  deliveryZipcode: 'Delivery Zipcode',
};

const MainContent = ({
  firebaseUser,
  searchField,
  handleChangeSearchField,
  searchInput,
  handleSearch,
  handleClear,
  handleViewInvoice,
  handleChangeSelectedStore,
  acknowledgedOrders,
  unacknowledgedOrders,
  unacknowledgedTotalCount,
  unacknowledgedPage,
  unacknowledgedRowsPerPage,
  unacknowledgedOrder,
  unacknowledgedOrderBy,
  acknowledgedTotalCount,
  acknowledgedPage,
  acknowledgedRowsPerPage,
  acknowledgedOrder,
  acknowledgedOrderBy,
  setUnacknowledgedOrder,
  setUnacknowledgedOrderBy,
  setUnacknowledgedPage,
  setUnacknowledgedRowsPerPage,
  setAcknowledgedOrder,
  setAcknowledgedOrderBy,
  setAcknowledgedPage,
  setAcknowledgedRowsPerPage,
  showStoreFilter,
  selectedHandoffType,
  setSelectedHandoffType,
  selectedOloStore,
  stores,
  setLoading,
  formData,
  startDate,
  endDate,
  searchQuery,
}) => {
  const [selectedUnacknowledgedOrders, setSelectedUnacknowledgedOrders] =
    useState({});
  const [selectedAcknowledgedOrders, setSelectedAcknowledgedOrders] = useState(
    {}
  );
  const [activeTable, setActiveTable] = useState(null); // Tracks which table is currently active

  const handleExport = async () => {
    try {
      const fileName = prompt(
        'Enter the file name for the export (without extension):'
      );
      if (!fileName) {
        alert('Export cancelled. Please provide a valid file name.');
        return;
      }
      setLoading(true);
      let goghUser;
      if (firebaseUser.email.toLowerCase().includes('gogh')) {
        goghUser = '&delivery=true';
      }
      let apiUrl = `/hd-api/olo/export?user=${firebaseUser.email}&role=${
        formData.role
      }&searchField=${searchField}&storeId=${selectedOloStore}${
        goghUser ? goghUser : ''
      }${selectedHandoffType ? `&handoff=${selectedHandoffType}` : ''}`;

      if (searchField === 'timeWanted' || searchField === 'timePlaced') {
        if (startDate) {
          apiUrl += `&startDate=${startDate?.toISOString()}`;
        }
        if (endDate) {
          apiUrl += `&endDate=${endDate?.toISOString()}`;
        }
      } else {
        apiUrl += `&searchQuery=${searchQuery}`;
      }

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      const response = await axios.post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          responseType: 'blob',
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to export orders:', error);
    }
  };

  const handleToggleAcknowledged = async () => {
    try {
      setLoading(true);

      const isAcknowledged = activeTable === 'acknowledged';
      const selectedOrderIds = Object.keys(
        isAcknowledged
          ? selectedAcknowledgedOrders
          : selectedUnacknowledgedOrders
      ).filter((id) =>
        isAcknowledged
          ? selectedAcknowledgedOrders[id]
          : selectedUnacknowledgedOrders[id]
      );

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      await axios.post(
        `/hd-api/olo/email=${firebaseUser.email}/mark=${
          !isAcknowledged ? 1 : 0
        }`,
        { orders: selectedOrderIds },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      // Clear the selected orders after the action
      setSelectedUnacknowledgedOrders({});
      setSelectedAcknowledgedOrders({});
      setActiveTable(null);
      handleSearch();
      handleSearch(1);
    } catch (error) {
      // Clear the selected orders after the action
      setSelectedUnacknowledgedOrders({});
      setSelectedAcknowledgedOrders({});
      setActiveTable(null);
      setLoading(false);
      console.error('Failed to mark orders:', error);
    }
  };

  const handleRequestSort = (event, property, table) => {
    const isAsc =
      (table === 'unacknowledged'
        ? unacknowledgedOrderBy
        : acknowledgedOrderBy) === property &&
      (table === 'unacknowledged' ? unacknowledgedOrder : acknowledgedOrder) ===
        'asc';
    if (table === 'unacknowledged') {
      setUnacknowledgedOrder(isAsc ? 'desc' : 'asc');
      setUnacknowledgedOrderBy(property);
    } else {
      setAcknowledgedOrder(isAsc ? 'desc' : 'asc');
      setAcknowledgedOrderBy(property);
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'timeWanted' || orderBy === 'timePlaced') {
      return new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array, comparator) => {
    if (array?.length > 0) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    } else {
      return [];
    }
  };

  const handleRowClick = (oloRef, isAcknowledged) => {
    if (isAcknowledged) {
      setSelectedUnacknowledgedOrders({});
      setActiveTable('acknowledged');
      setSelectedAcknowledgedOrders((prev) => ({
        ...prev,
        [oloRef]: !prev[oloRef],
      }));
    } else {
      setSelectedAcknowledgedOrders({});
      setActiveTable('unacknowledged');
      setSelectedUnacknowledgedOrders((prev) => ({
        ...prev,
        [oloRef]: !prev[oloRef],
      }));
    }
  };

  const handleChangeHeaderCheckbox = (event, isAcknowledged) => {
    const newChecked = event.target.checked;
    const visibleOrders = stableSort(
      isAcknowledged ? acknowledgedOrders : unacknowledgedOrders,
      getComparator(
        isAcknowledged ? acknowledgedOrder : unacknowledgedOrder,
        isAcknowledged ? acknowledgedOrderBy : unacknowledgedOrderBy
      )
    ).reduce(
      (acc, order) => ({
        ...acc,
        [order.oloRef]: newChecked,
      }),
      {}
    );

    if (isAcknowledged) {
      setSelectedAcknowledgedOrders(visibleOrders);
      setSelectedUnacknowledgedOrders({});
      setActiveTable('acknowledged');
    } else {
      setSelectedUnacknowledgedOrders(visibleOrders);
      setSelectedAcknowledgedOrders({});
      setActiveTable('unacknowledged');
    }
  };

  const handleChangeUnacknowledgedPage = (event, newPage) => {
    setUnacknowledgedPage(newPage);
  };
  const handleChangeAcknowledgedPage = (event, newPage) => {
    setAcknowledgedPage(newPage);
  };

  const handleChangeUnacknowledgedRowsPerPage = (event) => {
    setUnacknowledgedRowsPerPage(parseInt(event.target.value, 10));
    setUnacknowledgedPage(0);
  };

  const handleChangeAcknowledgedRowsPerPage = (event) => {
    setAcknowledgedRowsPerPage(parseInt(event.target.value, 10));
    setAcknowledgedPage(0);
  };

  let goghUser = firebaseUser.email.toLowerCase().includes('gogh');

  const toggleAcknowledgedText =
    activeTable === 'acknowledged'
      ? 'Mark as Unacknowledged'
      : 'Mark as Acknowledged';

  return (
    <div>
      <FilterActionBar
        firebaseUser={firebaseUser}
        activeTable={activeTable}
        searchField={searchField}
        handleChangeSearchField={handleChangeSearchField}
        searchInput={searchInput}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleExport={handleExport}
        handleToggleAcknowledged={handleToggleAcknowledged}
        toggleAcknowledgedText={toggleAcknowledgedText}
        selectedOrders={
          activeTable === 'acknowledged'
            ? selectedAcknowledgedOrders
            : selectedUnacknowledgedOrders
        }
        showStoreFilter={showStoreFilter}
        selectedHandoffType={selectedHandoffType}
        setSelectedHandoffType={setSelectedHandoffType}
        selectedOloStore={selectedOloStore}
        handleChangeSelectedStore={handleChangeSelectedStore}
        stores={stores}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Accordion
          defaultExpanded
          style={{ width: '100%', backgroundColor: '#f8f8f8', marginBottom: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <Typography variant='h5' style={{ fontFamily: 'Lato' }}>
              Unacknowledged Orders
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table
              orders={unacknowledgedOrders}
              order={unacknowledgedOrder}
              totalCount={unacknowledgedTotalCount}
              orderBy={unacknowledgedOrderBy}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, 'unacknowledged')
              }
              onRowClick={(oloRef) => handleRowClick(oloRef, false)}
              onHeaderCheckboxChange={(event) =>
                handleChangeHeaderCheckbox(event, false)
              }
              selectedOrders={selectedUnacknowledgedOrders}
              onPageChange={handleChangeUnacknowledgedPage}
              onRowsPerPageChange={handleChangeUnacknowledgedRowsPerPage}
              rowsPerPage={unacknowledgedRowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={unacknowledgedPage}
              columnMap={columnMap}
              goghUser={goghUser}
              handleViewInvoice={handleViewInvoice}
              acknowledged={false}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          style={{ width: '100%', backgroundColor: '#f8f8f8' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h5' style={{ fontFamily: 'Lato' }}>
              Acknowledged Orders
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table
              orders={acknowledgedOrders}
              order={acknowledgedOrder}
              orderBy={acknowledgedOrderBy}
              totalCount={acknowledgedTotalCount}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, 'acknowledged')
              }
              onRowClick={(oloRef) => handleRowClick(oloRef, true)}
              onHeaderCheckboxChange={(event) =>
                handleChangeHeaderCheckbox(event, true)
              }
              selectedOrders={selectedAcknowledgedOrders}
              onPageChange={handleChangeAcknowledgedPage}
              onRowsPerPageChange={handleChangeAcknowledgedRowsPerPage}
              rowsPerPage={acknowledgedRowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={acknowledgedPage}
              columnMap={columnMap}
              goghUser={goghUser}
              handleViewInvoice={handleViewInvoice}
              acknowledged={true}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default MainContent;
