import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  InputBase,
  Button,
  Stack,
  Typography,
  FormControl,
  NativeSelect,
  InputLabel,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { startOfToday, startOfYesterday, startOfTomorrow } from 'date-fns';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const PrintDialog = ({
  selectedOloStore,
  selectedOrders,
  loading,
  open,
  onClose,
  onPrint,
  stores,
}) => {
  const [storeId, setStoreId] = useState(selectedOloStore || 'all');

  useEffect(() => {
    setStoreId(selectedOloStore);
  }, [selectedOloStore]);

  const handlePrintInvoices = (selectedRange) => {
    let startDate, selected;

    switch (selectedRange) {
      case 'yesterday':
        startDate = startOfYesterday();
        break;
      case 'today':
        startDate = startOfToday();
        break;
      case 'tomorrow':
        startDate = startOfTomorrow();
        break;
      case 'selected':
        selected = true;
        break;
      //   case 'next_week':
      //     startDate = startOfWeek(addWeeks(new Date(), 1));
      //     endDate = endOfWeek(addWeeks(new Date(), 1));
      //     break;
      default:
        return;
    }

    onPrint(storeId, startDate, selected, handleClose);
  };

  const handleClose = () => {
    setStoreId(undefined); // Reset the storeId to undefined when dialog closes
    onClose(); // Call the original onClose function to close the dialog
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
      <DialogTitle>
        <Typography variant='h6' align='center' style={{ fontFamily: 'Lato' }}>
          {!loading
            ? `Select ${
                (storeId === 'all' &&
                  Object.keys(selectedOrders).length === 0) ||
                (!storeId && Object.keys(selectedOrders).length === 0)
                  ? 'Store'
                  : 'Date Range / Selection'
              }`
            : `Building your pdf...`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          (storeId === 'all' || !storeId) &&
          Object.keys(selectedOrders).length === 0 ? (
            <FormControl fullWidth style={{ marginTop: 16 }}>
              <InputLabel
                id='store-select-label'
                style={{ fontFamily: 'Lato' }}
              >
                Select Store
              </InputLabel>
              <NativeSelect
                labelId='store-select-label'
                value={storeId}
                onChange={(e) => setStoreId(e.target.value)}
                style={{ fontFamily: 'Lato' }}
                input={<BootstrapInput />}
              >
                {stores.map((store) => (
                  <option key={store.storeId} value={store.storeId}>
                    {store.storeName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          ) : (
            <Stack spacing={2} mt={1}>
              <Button
                style={{ fontFamily: 'Lato' }}
                variant='outlined'
                onClick={() => handlePrintInvoices('yesterday')}
                fullWidth
              >
                Yesterday
              </Button>
              <Button
                style={{ fontFamily: 'Lato' }}
                variant='outlined'
                onClick={() => handlePrintInvoices('today')}
                fullWidth
              >
                Today
              </Button>
              <Button
                style={{ fontFamily: 'Lato' }}
                variant='outlined'
                onClick={() => handlePrintInvoices('tomorrow')}
                fullWidth
              >
                Tomorrow
              </Button>
              <Divider />
              <Button
                style={{ fontFamily: 'Lato' }}
                variant='outlined'
                onClick={() => handlePrintInvoices('selected')}
                fullWidth
              >
                Selected Orders
              </Button>
              {/* <Button
                style={{ fontFamily: 'Lato' }}
                variant='outlined'
                onClick={() => handlePrintInvoices('this_week')}
                fullWidth
              >
                This Week
              </Button>
              <Button
                style={{ fontFamily: 'Lato' }}
                variant='outlined'
                onClick={() => handlePrintInvoices('next_week')}
                fullWidth
              >
                Next Week
              </Button> */}
            </Stack>
          )
        ) : (
          <Grid container justifyContent={'center'}>
            <CircularProgress style={{ marginTop: 24 }} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ fontFamily: 'Lato' }}
          onClick={handleClose}
          color='error'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintDialog;
