import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Checkbox,
  Button,
  IconButton,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, '');
  if (phoneNumber.startsWith('1')) {
    phoneNumber = phoneNumber.substring(1);
  }
  if (phoneNumber.length !== 10) {
    return 'Invalid phone number';
  }
  const areaCode = phoneNumber.substring(0, 3);
  const firstThree = phoneNumber.substring(3, 6);
  const lastFour = phoneNumber.substring(6);
  return `(${areaCode}) ${firstThree}-${lastFour}`;
}

// Utility function to check if values differ between parent and child orders
const hasDifference = (parentOrder, childOrder, field) => {
  if (!parentOrder || !childOrder.timeWantedChanged) return false;
  return parentOrder[field] !== childOrder[field];
};
// Child row component for parent order with difference notification
const ChildOrderRow = ({
  parentOrder,
  childOrder,
  goghUser,
  handleViewInvoice,
}) => (
  <TableRow
    sx={{
      backgroundColor: '#fff44e82',
      '&:hover': {
        backgroundColor: '#fff44e9e',
      },
    }}
  >
    {/* Label to indicate this is the original order */}
    <TableCell colSpan={2} style={{ fontStyle: 'italic', color: '#888' }}>
      Original Order
    </TableCell>
    <TableCell>
      <Button
        onClick={() =>
          handleViewInvoice(parentOrder.exportBatchNumber, parentOrder.oloRef)
        }
      >
        {parentOrder.oloRef}
      </Button>
    </TableCell>
    <TableCell>{parentOrder.storeName}</TableCell>

    {/* Highlight if timeWanted is different */}
    <TableCell>
      {parentOrder.timeWanted}
      {/* {hasDifference(parentOrder, childOrder, 'timeWanted') && (
        <WarningIcon fontSize='small' sx={{ ml: 1 }} />
      )} */}
    </TableCell>

    <TableCell>{parentOrder.timePlaced}</TableCell>
    <TableCell>{parentOrder.name}</TableCell>
    <TableCell>{parentOrder.emailAddress}</TableCell>
    <TableCell>{formatPhoneNumber(parentOrder.phoneNumber)}</TableCell>

    {goghUser && (
      <>
        <TableCell>{parentOrder.deliveryAddress}</TableCell>
        <TableCell>{parentOrder.deliveryZipcode}</TableCell>
      </>
    )}

    {/* Highlight if paymentType, total, tax, or tip are different */}
    <TableCell>{parentOrder.paymentType}</TableCell>
    <TableCell
      sx={{
        color: hasDifference(parentOrder, childOrder, 'total')
          ? '#ff0000f2'
          : 'inherit',
      }}
    >
      ${Number(parentOrder.total).toFixed(2)}
      {/* {hasDifference(parentOrder, childOrder, 'total') && (
        <WarningIcon fontSize='small' sx={{ ml: 1 }} />
      )} */}
    </TableCell>
    <TableCell
      sx={{
        color: hasDifference(parentOrder, childOrder, 'tax')
          ? '#ff0000f2'
          : 'inherit',
      }}
    >
      ${Number(parentOrder.tax).toFixed(2)}
      {/* {hasDifference(parentOrder, childOrder, 'tax') && (
        <WarningIcon fontSize='small' sx={{ ml: 1 }} />
      )} */}
    </TableCell>
    <TableCell
      sx={{
        color: hasDifference(parentOrder, childOrder, 'tip')
          ? '#ff0000f2'
          : 'inherit',
      }}
    >
      ${Number(parentOrder.tip).toFixed(2)}
      {/* {hasDifference(parentOrder, childOrder, 'tip') && (
        <WarningIcon fontSize='small' sx={{ ml: 1 }} />
      )} */}
    </TableCell>
  </TableRow>
);

const OrderTable = ({
  orders,
  order,
  orderBy,
  totalCount,
  handleRequestSort,
  onRowClick,
  onHeaderCheckboxChange,
  selectedOrders,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  page,
  columnMap,
  goghUser,
  handleViewInvoice,
  acknowledged,
  stableSort,
  getComparator,
}) => {
  const [openRows, setOpenRows] = useState({});

  const handleSortRequest = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRowClick = (oloRef) => {
    setOpenRows((prev) => ({ ...prev, [oloRef]: !prev[oloRef] }));
  };

  const sortedRows = stableSort(orders, getComparator(order, orderBy));

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                indeterminate={
                  Object.values(selectedOrders).some(Boolean) &&
                  !Object.values(selectedOrders).every(Boolean)
                }
                checked={
                  Object.values(selectedOrders).length > 0 &&
                  Object.values(selectedOrders).every(Boolean)
                }
                onChange={onHeaderCheckboxChange}
              />
            </TableCell>
            {[
              'timeWantedChanged',
              'oloRef',
              'storeName',
              'timeWanted',
              'timePlaced',
              'name',
              'emailAddress',
              'phoneNumber',
              ...(goghUser ? ['deliveryAddress', 'deliveryZipcode'] : []),
              'paymentType',
              'total',
              'tax',
              'tip',
            ].map((column) => (
              <TableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={handleSortRequest(column)}
                >
                  {columnMap[column]}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((orderData, index) => (
            <React.Fragment key={index}>
              <TableRow
                selected={selectedOrders[orderData.oloRef]}
                sx={{
                  backgroundColor: orderData.timeWantedChanged
                    ? '#fff44e54' // A light shade of orange
                    : orderData.paymentType === 'instore'
                    ? '#ff000024' // The existing red shade
                    : acknowledged
                    ? '#f0f0f0'
                    : 'inherit',
                  '&:hover': {
                    backgroundColor: orderData.timeWantedChanged
                      ? '#fff44e8f' // A darker shade of orange on hover
                      : orderData.paymentType === 'instore'
                      ? '#ff000042' // The darker red on hover
                      : acknowledged
                      ? '#e0e0e0'
                      : '#f5f5f5',
                  },
                }}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    onClick={() => onRowClick(orderData.oloRef)}
                    checked={selectedOrders[orderData.oloRef] || false}
                  />
                </TableCell>
                <TableCell>
                  {orderData.timeWantedChanged ? (
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => handleRowClick(orderData.oloRef)}
                    >
                      {openRows[orderData.oloRef] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  ) : (
                    <IconButton aria-label='expand row' size='small' disabled>
                      <KeyboardArrowDown />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align='left'>
                  <Button
                    onClick={() =>
                      handleViewInvoice(
                        orderData.exportBatchNumber,
                        orderData.oloRef
                      )
                    }
                  >
                    {orderData.oloRef}
                  </Button>
                </TableCell>
                <TableCell>{orderData.storeName}</TableCell>
                <TableCell
                  sx={{
                    fontWeight: hasDifference(
                      orderData.parentOrder,
                      orderData,
                      'timeWanted'
                    )
                      ? 'bold'
                      : 'inherit',
                  }}
                >
                  {orderData.timeWanted}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: hasDifference(
                      orderData.parentOrder,
                      orderData,
                      'timePlaced'
                    )
                      ? 'bold'
                      : 'inherit',
                  }}
                >
                  {orderData.timePlaced}
                </TableCell>
                <TableCell>{orderData.name}</TableCell>
                <TableCell>{orderData.emailAddress}</TableCell>
                <TableCell>
                  {formatPhoneNumber(orderData.phoneNumber)}
                </TableCell>
                {goghUser && (
                  <>
                    <TableCell>{orderData.deliveryAddress}</TableCell>
                    <TableCell>{orderData.deliveryZipcode}</TableCell>
                  </>
                )}
                <TableCell
                  sx={{
                    fontWeight: hasDifference(
                      orderData.parentOrder,
                      orderData,
                      'paymentType'
                    )
                      ? 'bold'
                      : 'inherit',
                  }}
                >
                  {orderData.paymentType}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: hasDifference(
                      orderData.parentOrder,
                      orderData,
                      'total'
                    )
                      ? 'bold'
                      : 'inherit',
                  }}
                >
                  ${Number(orderData.total).toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: hasDifference(
                      orderData.parentOrder,
                      orderData,
                      'tax'
                    )
                      ? 'bold'
                      : 'inherit',
                  }}
                >
                  ${Number(orderData.tax).toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: hasDifference(
                      orderData.parentOrder,
                      orderData,
                      'tip'
                    )
                      ? 'bold'
                      : 'inherit',
                  }}
                >
                  ${Number(orderData.tip).toFixed(2)}
                </TableCell>
              </TableRow>
              {orderData.timeWantedChanged && openRows[orderData.oloRef] ? (
                <ChildOrderRow
                  parentOrder={orderData.parentOrder}
                  childOrder={orderData} // Passing the current order as childOrder
                  goghUser={goghUser}
                  handleViewInvoice={handleViewInvoice}
                />
              ) : null}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default OrderTable;
